import { PageType, useFallbackStorageValue } from "@product/scmp-sdk";
import { useMountEffect } from "@react-hookz/web";
import deepmerge from "deepmerge";
import { useAtom, useAtomValue } from "jotai";
import qs from "qs";
import { useCallback, useEffect } from "react";
import useSWRImmutable from "swr/immutable";

import { useSnackbarSimple } from "scmp-app/components/common/snackbar/hooks";
import { bookmarkAtom } from "scmp-app/components/header/header-bookmark/atoms";
import { useLoginDialogStateHelper } from "scmp-app/components/login-dialog/hooks";
import type { BookmarkArticleLocation } from "scmp-app/components/mixpanel";
import {
  registerMixPanelParameters,
  trackMixPanel,
  useMixpanel,
} from "scmp-app/components/mixpanel";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import type { Event as GA4Event } from "scmp-app/components/tracking/google-analytics-4/types";
import type { UntypedGA4Event } from "scmp-app/components/tracking/google-tag-manager/types";
import { config } from "scmp-app/data";
import { accountAtom } from "scmp-app/lib/account/atoms";
import { useDeferredAction } from "scmp-app/lib/hooks";
import { useCurrentPageType } from "scmp-app/lib/router/hooks";
import { addMinutes } from "scmp-app/lib/utils";

import { create, fetchList } from "./apis";
import { userBookmarkAtom } from "./atoms";
import { BookmarkedContentListSwrFetchKey, BookmarkedLastSyncKey } from "./consts";
import { StyledSnackbarAction, StyledSnackbarMessage } from "./styles";
import type {
  BookmarkActions,
  BookmarkLocation,
  BookmarkPayload,
  BookmarkQueryStringPayload,
} from "./types";
import { generateNewList } from "./utils";

export const useUserBookmarkInit = () => {
  const { isLoggedIn } = useAtomValue(accountAtom);
  const [{ lastSync }, setUserBookmarkState] = useAtom(userBookmarkAtom);

  const { set: setLastSync, value: storedLastSync } = useFallbackStorageValue(
    BookmarkedLastSyncKey,
    {
      defaultValue: 0,
    },
  );

  useMountEffect(() => {
    setUserBookmarkState(current => ({ ...current, lastSync: storedLastSync ?? 0 }));
  });

  useEffect(() => {
    if (lastSync === undefined) return;
    setLastSync(lastSync);
  }, [lastSync, setLastSync]);

  const getIsDataOutDated = useCallback(
    () => Date.now() > addMinutes(lastSync ?? 0, 10).getTime(),
    [lastSync],
  );

  const { data, mutate } = useSWRImmutable(
    isLoggedIn ? BookmarkedContentListSwrFetchKey : null,
    fetchList,
    {
      isPaused: () => !getIsDataOutDated(),
      onSuccess: () => setLastSync(Date.now()),
      refreshInterval: 1000 * 30,
      revalidateOnMount: true,
    },
  );

  useEffect(() => {
    if (!data || !mutate) return;
    setUserBookmarkState(current => ({ ...current, data, mutate }));
  }, [data, mutate, setUserBookmarkState]);
};

export const useBookmark = () => {
  const { isLoggedIn } = useAtomValue(accountAtom);
  const [{ data, mutate }, setUserBookmarkState] = useAtom(userBookmarkAtom);
  const currentPageType = useCurrentPageType();
  const mixpanel = useMixpanel();

  const handleMixpanelTracking = useCallback(
    (action: BookmarkActions, entityId: string, trackings?: { location?: BookmarkLocation }) => {
      const computePageLocation: () => BookmarkArticleLocation | null = () => {
        switch (currentPageType) {
          case PageType.Article:
            switch (trackings?.location) {
              case "header":
                return "Article Adhesive";
              case "side":
                return "Article Side";
              case "others":
                return "Article";
              default:
                return null;
            }
          case PageType.Author:
            return "Author Page";
          case PageType.Homepage:
            return "Homepage";
          case PageType.Others:
            return "Other Index";
          case PageType.Section:
            return "Section";
          case PageType.Topic:
            return "Topic Index";
          default:
            return null;
        }
      };

      const pageLocation = computePageLocation();
      if (!pageLocation) return;
      registerMixPanelParameters(mixpanel.result, {
        "Article ID": entityId,
        Location: pageLocation,
      });
      trackMixPanel(mixpanel.result, {
        name: "Bookmark Article",
        property: {
          Action: action === "bookmark" ? "Add" : "Remove",
          Location: pageLocation,
        },
      });
    },
    [currentPageType, mixpanel.result],
  );

  const { deferRun } = useDeferredAction([!!data, !!mutate]);
  const { handleOpenSnackbar } = useSnackbarSimple();
  const { openLoginDialog } = useLoginDialogStateHelper();
  const { toggleBookmarkRippleEffect, toggleShowNewBookmarkAnimation } = useAtomValue(bookmarkAtom);
  const handleBookmarkAction = useCallback(
    <Untyped extends true | false>(
      payload: BookmarkPayload,
      trackings?: {
        ga4Events?: Record<"click", Untyped extends true ? GA4Event : UntypedGA4Event>;
        location?: BookmarkLocation;
      },
      callback?: () => void,
    ) => {
      const ga4ActionType = { bookmark: "add", unbookmark: "remove" }[payload.action];
      if (trackings?.ga4Events?.click) {
        sendGA4Tracking<true>(
          deepmerge(trackings.ga4Events.click as UntypedGA4Event, {
            customized_parameters: {
              action_type: ga4ActionType,
            },
          }),
          {
            untyped: true,
          },
        );
      }
      handleMixpanelTracking(payload.action, payload.id);

      if (isLoggedIn) {
        if (!mutate) {
          return;
        }
        deferRun(async () => {
          await mutate(data => create(data, payload), {
            optimisticData: data => generateNewList(data, payload),
            populateCache: true,
            revalidate: false,
            rollbackOnError: true,
          });
          setUserBookmarkState(current => ({ ...current, lastSync: Date.now() }));

          if (trackings?.ga4Events?.click) {
            sendGA4Tracking<true>(
              deepmerge(trackings.ga4Events.click as UntypedGA4Event, {
                action: "sys",
                customized_parameters: {
                  action_type: ga4ActionType,
                },
              }),
              {
                untyped: true,
              },
            );
          }

          const snackbarProps = {
            duration: 4000,
            leftElement: (
              <StyledSnackbarMessage>
                {payload.action === "bookmark" ? "Bookmarked" : "Unbookmarked"}
              </StyledSnackbarMessage>
            ),
            rightElement: (
              <StyledSnackbarAction pathname={`${config.account.scmpAccountHost}/manage/bookmark`}>
                View all
              </StyledSnackbarAction>
            ),
          };
          handleOpenSnackbar(snackbarProps);

          if (payload.action === "bookmark") {
            toggleBookmarkRippleEffect();
            toggleShowNewBookmarkAnimation(true);
          }
          callback?.();
        });
      } else {
        const queryStringPayload: BookmarkQueryStringPayload = {
          action: {
            bookmark: payload.id,
          },
        };
        const accountUrl = new URL(window.location.href);
        accountUrl.search = qs.stringify(queryStringPayload);
        openLoginDialog({
          description: "Login or register to save your favourite articles.",
          destination: accountUrl.toString(),
          ga4CustomParameter: {
            trigger_point: "bookmark",
          },
          title: "Bookmark this story",
        });
      }
    },
    [
      deferRun,
      handleMixpanelTracking,
      handleOpenSnackbar,
      isLoggedIn,
      mutate,
      openLoginDialog,
      setUserBookmarkState,
      toggleBookmarkRippleEffect,
      toggleShowNewBookmarkAnimation,
    ],
  );

  const checkIsBookmarked = useCallback(
    (entityUuid: string) => data?.includes(entityUuid) ?? false,
    [data],
  );

  return {
    checkIsBookmarked,
    handleBookmarkAction,
  };
};
