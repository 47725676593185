import { atom } from "jotai";
import type { KeyedMutator } from "swr";

import type { UserBookmarkList } from "./types";

type State = {
  data?: UserBookmarkList;
  lastSync?: number;
  mutate?: KeyedMutator<UserBookmarkList>;
};

export const userBookmarkAtom = atom<State>({});
