import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontPlayfairDisplay } from "@product/scmp-sdk";

type ContentSubHeadlineContainerProps = {
  $variant?: "magazines-style";
};
export const ContentSubHeadlineContainer = styled.div<ContentSubHeadlineContainerProps>`
  ul {
    position: relative;

    padding-inline-start: 10px;
    li {
      &:before {
        position: absolute;
        inset-inline-start: 0;
        content: "•";
      }
    }
  }

  ${props => {
    if (props.$variant === "magazines-style") {
      return css`
        color: #000000;
        font-weight: 400;
        font-size: 18px;
        font-family: ${fontPlayfairDisplay};
        font-style: italic;
        line-height: 32px;

        ${props.theme.breakpoints.up("tablet")} {
          font-size: 20px;
          line-height: 33px;
        }

        ul {
          padding-inline-start: 0;
          li {
            margin-block-start: 33px;

            text-indent: 14px;
            &:before {
              content: "+" !important;

              transform: translateX(-14px);
            }
            &:first-child {
              margin-block-start: 0;
            }
            ${props.theme.breakpoints.up("tablet")} {
              text-indent: 20px;
              &:before {
                transform: translateX(-20px);
              }
            }
          }
        }
      `;
    }
  }}
`;
