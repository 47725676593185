import { createContextValueWarningProxy } from "@product/scmp-sdk";
import type { AsyncState } from "@react-hookz/web";
import { useAsync } from "@react-hookz/web";
import { useAtomValue } from "jotai";
import uniqueId from "lodash/uniqueId";
import type { Mixpanel } from "mixpanel-browser";
import type { FunctionComponent, ReactNode } from "react";
import { createContext, useContext, useEffect } from "react";

import { config } from "scmp-app/data";
import { accountAtom, registerEmailCookieAtom } from "scmp-app/lib/account/atoms";
import { isExternalScriptsDisabled } from "scmp-app/lib/utils";

export * from "./api";

type ContextValue = AsyncState<Nullish<Mixpanel>>;
const MixpanelContext = createContext<ContextValue>(
  createContextValueWarningProxy("MixpanelProvider"),
);

export type Props = {
  children?: ReactNode;
};

/**
 * Not using jotai here is because mixpanel is not a primitive object,
 * it can't be Object.freeze by jotai hence it will throw error when set the state inside jotai
 */
export const MixpanelProvider: FunctionComponent<Props> = ({ children }) => {
  const { loginType, user } = useAtomValue(accountAtom);
  const { registerEmail } = useAtomValue(registerEmailCookieAtom);

  const [asyncState, { execute }] = useAsync(
    async () =>
      new Promise<Mixpanel | null>(async resolve => {
        if (!user || !config.account.mixPanelToken || isExternalScriptsDisabled("mixpanel")) {
          resolve(null);
          return;
        }
        const mixpanel = await import("mixpanel-browser");
        mixpanel.init(
          config.account.mixPanelToken,
          {
            loaded(mixpanel) {
              if (registerEmail) {
                mixpanel.alias(user.uuid);
              } else {
                mixpanel.identify(user.uuid);
              }

              mixpanel.people.set({ $email: user.username, uuid: user.uuid });

              if (loginType) {
                mixpanel.register({ "Login Channel": decodeURIComponent(loginType) });
              }
              resolve(mixpanel);
            },
            persistence: "localStorage",
            persistence_name: "super_properties",
          },
          /*  Context:
                Since mixpanel won't call the `loaded` callback when the instance with same or no name is init again,
                and react will invoke use effect twice in strict mode thus calling mixpanel.init twice,
                thus the second `useAsync` will be forever is loading state
              Workaround:
                Use unique id for each instance created, since in strict mode the effect is invoked twice
           */
          uniqueId(),
        );
      }),
  );
  useEffect(() => void execute(), [execute, user]);

  return <MixpanelContext.Provider value={asyncState}>{children}</MixpanelContext.Provider>;
};

MixpanelProvider.displayName = "MixpanelProvider";

export const useMixpanel = () => useContext(MixpanelContext);
