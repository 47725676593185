import { BookmarkButton } from "@product/scmp-sdk";
import type { FunctionComponent } from "react";
import React, { useCallback } from "react";

import type { BookmarkClickEvent } from "scmp-app/components/tracking/google-analytics-4/types";

import { useBookmark } from "./hooks";
import type { BookmarkActions, BookmarkLocation } from "./types";

type Props = {
  className?: string;
  entityId: string;
  ga4Events?: { click: BookmarkClickEvent };
  location?: BookmarkLocation;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export const ContentBookmark: FunctionComponent<Props> = ({
  className,
  entityId,
  ga4Events,
  location = "others",
  onClick,
}) => {
  const { checkIsBookmarked, handleBookmarkAction } = useBookmark();

  const handleClick = useCallback(
    (action: BookmarkActions, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      handleBookmarkAction(
        { action, id: entityId },
        {
          ga4Events,
          location,
        },
      );
      onClick?.(event);
    },
    [entityId, ga4Events, handleBookmarkAction, location, onClick],
  );

  return (
    <BookmarkButton
      className={className}
      isBookmarked={checkIsBookmarked(entityId)}
      onClick={handleClick}
    />
  );
};

ContentBookmark.displayName = "ContentBookmark";
